"use client";

import React, { useMemo, useState } from "react";
import { Stack } from "@mui/material";

import { MhcConfidenceInterval, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { SUPPRESSION_MESSAGE } from "common/constants";
import { countyColors, CountyKeys } from "theme/colors";
import AboutTheDataAccordion from "../util/elementHelpers/Covid/common/components/AboutTheDataAccordion";
import {
  LoadedLocationStatDictionary,
  LoadedLocationStatDictionaryByLocation,
  LoadedStat
} from "../util/fetchingFunctions/fetchStatsForAllSections";
import { getSmallestGranularity } from "../util/fetchingFunctions/kpi/util";
import { seriesForTable } from "common/components/charts/ChartSeriesTable/util";
import { defaultChartColorSet } from "common/components/charts/util/color/config";
import {
  ChartSeriesOptionsDictionary,
  ChartSeriesOptionsDictionaryItem,
  statsToSeries
} from "common/components/charts/util/series";

import { MhcAlert } from "common/components/Alerts/MhcAlert";
import { DynamicChart } from "common/components/charts/Chart/DynamicChart";
import ChartSeriesTable from "common/components/charts/ChartSeriesTable";
import ConfidenceIntervalControls from "common/components/charts/ConfidenceIntervalControls";
import InvestigateChartTitles, {
  InvestigateChartTitlesProps
} from "common/components/charts/Investigate/InvestigateChartTitles";

type TopicLocationComparisonChartProps = {
  loadedStatDictionary: LoadedLocationStatDictionaryByLocation;
  title: string;
  subtitle?: string;
  locationId: string;
  locationName: string;
  topicName?: string;
};
const TopicLocationComparisonChart: React.FC<TopicLocationComparisonChartProps> = ({
  locationId,
  locationName,
  loadedStatDictionary,
  title,
  topicName
}) => {
  const [showConfidenceIntervals, setShowConfidenceIntervals] = useState(false);
  const locationStats = Object.values(loadedStatDictionary)?.[0] as LoadedLocationStatDictionary;
  const [stats, confidenceIntervals] = useMemo(() => {
    const stats: (LoadedStat & { _id?: string })[] = [];
    const confidenceIntervals: MhcConfidenceInterval[][] = [];
    Object.entries(locationStats).forEach(([locationId, stat]) => {
      stats.push({
        ...stat,
        _id: [stat.statIdentifier.id, locationId].join("-")
      });
      stat.timeSeries?.confidenceIntervals?.length &&
        confidenceIntervals.push(stat.timeSeries?.confidenceIntervals ?? []);
    });
    return [stats, confidenceIntervals];
  }, [locationStats]);
  const statIdentifier = (stats[0] as LoadedStat).statIdentifier;
  const titleProps = useMemo((): InvestigateChartTitlesProps | null => {
    if (!title) return null;
    return {
      title,
      subtitle: statIdentifier?.subtitle,
      locationName: locationId.includes("county") ? undefined : locationName
    };
  }, [title, locationId, locationName, statIdentifier?.subtitle]);

  if (stats.length === 0) return null;

  const attributions = statIdentifier.attributions ?? [];
  const granularities: MhcTimeSeriesGranularityEnum[] = [];

  const options = stats.reduce((opts, stat, i) => {
    granularities.push(stat.granularity as MhcTimeSeriesGranularityEnum);
    const statLocationId = stat.location?.id ?? `location-${i}`;
    const isCounty = statLocationId.startsWith("county-");
    const colorKey = statLocationId.replace("county-", "") as CountyKeys;
    opts[[stat.statIdentifier.id, statLocationId].join("-")] = {
      id: stat.location?.id ?? `series-${i}`,
      name: stat.location?.name ?? `Series ${i}`,
      dashStyle: locationId === statLocationId ? "Solid" : "Dash",
      type: "line",
      lineWidth: locationId === statLocationId || !isCounty ? 3 : 2,
      zIndex: locationId === statLocationId || !isCounty ? 10 : 3,
      color:
        (isCounty ? countyColors[colorKey] : defaultChartColorSet[0]) ?? defaultChartColorSet[i]
    } as ChartSeriesOptionsDictionaryItem;
    return opts;
  }, {} as ChartSeriesOptionsDictionary);
  const granularity =
    getSmallestGranularity(granularities.filter((x) => x)) ?? MhcTimeSeriesGranularityEnum.Year;
  const series = statsToSeries({
    stats,
    options
  });
  return (
    <Stack gap={2}>
      {confidenceIntervals.length > 0 && (
        <Stack gap={2}>
          <MhcAlert severity="info">
            This chart has the option to view confidence limits as a means to provide precision and
            transparency.
          </MhcAlert>
          <ConfidenceIntervalControls
            showing={showConfidenceIntervals}
            onChange={() => setShowConfidenceIntervals((prev) => !prev)}
          />
        </Stack>
      )}
      <Stack gap={4}>
        {titleProps && <InvestigateChartTitles {...titleProps} />}
        <DynamicChart
          statIdentifier={statIdentifier}
          title={`${statIdentifier.name} by County`}
          series={series}
          granularity={granularity}
          confidenceIntervals={confidenceIntervals}
        />
        <ChartSeriesTable
          title={title}
          subtitle={statIdentifier.subtitle}
          series={seriesForTable(series)}
          granularity={granularity ?? MhcTimeSeriesGranularityEnum.Year}
          confidenceIntervals={confidenceIntervals}
          showConfidenceIntervals={showConfidenceIntervals}
          valueOptions={{
            unit: statIdentifier.unit,
            precision: statIdentifier.precision,
            isPercent: statIdentifier.isPercent
          }}
          locationId={locationId}
        />
        <Stack gap={2}>
          <MhcAlert severity="info">{SUPPRESSION_MESSAGE}</MhcAlert>
          <AboutTheDataAccordion
            title={attributions.length ? "About the data" : "Additional Information"}
            attributions={attributions}
            showDefaultContent={false}
            variant="grey"
            topicName={topicName}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TopicLocationComparisonChart;
