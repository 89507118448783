import { default as NextImage, ImageProps as NextImageProps } from "next/image";
import { Box, BoxProps, Typography } from "@mui/material";

import { LinkOrText } from "./LinkOrText";

export interface ImageProps extends NextImageProps {
  alt: string;
  borderRadius?: boolean;
  blur?: boolean;
  credit?: {
    name: string;
    url: string;
  };
}

const Image: React.FC<ImageProps> = ({ credit, borderRadius = true, blur = true, ...props }) => {
  let containerSx: BoxProps["sx"] = {};
  if (borderRadius) {
    containerSx = {
      borderRadius: "4px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column"
    };
  }
  return (
    <Box>
      <Box sx={containerSx}>
        <NextImage
          {...props}
          placeholder={blur ? "blur" : undefined}
          style={{
            ...(props.style ?? {}),
            maxHeight: "400px",
            maxWidth: "100%",
            objectFit: "cover"
          }}
        />
      </Box>
      {credit && (
        <Box display="flex" alignItems="baseline" mt={0.5} gap="3px">
          <Typography component="span" variant="caption" mr={0.25}>
            Photo credit:
          </Typography>
          <LinkOrText
            textComponent="span"
            textVariant="caption"
            renderAsLink={!!credit.url}
            href={credit.url}
            text={credit.name}
          />
        </Box>
      )}
    </Box>
  );
};

export default Image;
