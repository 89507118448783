import { EcdcPageSection } from "./types";
import { MhcStratumGroupEnum } from "graphqlApi/types";

import { ECDC_NAME } from "common/constants";

export const ecdcWealthSections: Array<EcdcPageSection> = [
  {
    id: "employment",
    name: "Employment",
    caption: `${ECDC_NAME} / Wealth Creation`,
    subSections: [
      {
        id: "UNEMPLOYMENT_RATE_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "PARENT_EMPLOYMENT_CENSUS_TOTAL_PERCENT",
        stratumGroups: [MhcStratumGroupEnum.Age]
      }
    ]
  },
  {
    id: "income",
    name: "Income and Poverty",
    caption: `${ECDC_NAME} / Wealth Creation`,
    subSections: [
      {
        id: "CHILD_POVERTY_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "FAMILY_POVERTY_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "MEDIAN_FAMILY_INCOME_CENSUS_TOTAL_DOLLARS",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "MEDIAN_HOUSEHOLD_INCOME_CENSUS_TOTAL_DOLLARS",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      }
    ]
  },
  {
    id: "housing",
    name: "Housing",
    caption: `${ECDC_NAME} / Wealth Creation`,
    subSections: [
      {
        id: "RENT_BURDEN_30_PLUS_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "HOUSING_RENTER_OCCUPIED_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "INTERNET_ACCESS_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      }
    ]
  },
  {
    id: "benefits",
    name: "Benefits",
    caption: `${ECDC_NAME} / Wealth Creation`,
    subSections: [
      {
        id: "SNAP_RECEIPT_CENSUS_TOTAL_PERCENT",
        stratumGroups: [MhcStratumGroupEnum.Race, MhcStratumGroupEnum.Age]
      }
    ]
  },
  {
    id: "deprivation",
    name: "Deprivation",
    caption: `${ECDC_NAME} / Wealth Creation`,
    subSections: [
      {
        id: "STATE_AREA_DEPRIVATION_INDEX",
        stratumGroups: []
      }
    ]
  }
];
