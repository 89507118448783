// TODO: Refactor element functions to *not* include useRouter or other hooks
"use client";

import { Typography } from "@mui/material";
import pick from "lodash/pick";
import pealingPaint from "public/images/topics/lead/pealing_paint_s-j-8fgAl2-i39Q-unsplash.jpg";

import { TopicDashboardSectionProps } from "../../dashboard/types";
import { TopicElementBaseRender } from "modules/Topics/types";

import useRouterPath from "common/util/hooks/usePathWithParamKeys";
import { getTopicDashboardSection } from "modules/Topics/util/getTopicDashboardSection";
import { updateBivariateSections } from "modules/Topics/util/sectionHelpers";

import { TopicDashboardSection } from "modules/Topics/components/TopicDashboardSection";
import { getLocationName } from "../..";

export const getHousingElement = <P extends { sections?: TopicDashboardSectionProps[] }>(
  id?: string
): TopicElementBaseRender<P> => ({
  id: id || "housing",
  render: ({ topic, sections, location }) => {
    // ES Lint doesn't know this is essentially a component render function
    // so it complains about useRouter being called outside of that context
    // eslint-disable-next-line
    const { pathWithParamKeys, params } = useRouterPath();
    if (!topic) return null;

    const { subSections, ...props } = getTopicDashboardSection(sections ?? [], "housing");

    const intro = (
      <>
        <Typography>
          Housing built before 1978 carries an elevated risk for lead exposure, and housing built
          before 1950 has the highest risk of lead exposure. Due to a ban on lead-based paint in
          1978, housing built after this year carries minimal risk. The Department of Housing and
          Urban Development (HUD) estimated that 89% of homes built before 1978 had lead-based
          paint, accounting for the majority of the 34.6 million American homes that have lead paint
          in them as of 2018-2019. A study of young children ages 6 months to 2 years found that
          interior renovation of older homes — which often includes painting, sanding, scraping or
          other activities that might release lead dust into the air — further increased risk of
          lead poisoning. Over 3 million homes with children younger than age 6 had one or more
          lead-based paint hazards in 2021, 2.1 million of which were low income households.
          Investing in lead paint hazard control for communities at high risk could provide a return
          of $17-$221 in societal and health costs for each $1 spent.
        </Typography>
        <Typography>
          In this map, we explore the relationship between the presence of older housing stock with
          testing rates within the community.
        </Typography>
        <Typography fontStyle="italic">
          All values for percent of children tested are 5-year averages.
        </Typography>
      </>
    );

    return (
      <TopicDashboardSection
        {...props}
        intro={intro}
        subSections={updateBivariateSections({
          subSections,
          location: pick(location, ["id", "name", "geography"]),
          pathWithParamKeys,
          params
        })}
        locationName={getLocationName(location)}
        image={{
          src: pealingPaint,
          alt: "Paint pealing off of a break wall",
          credit: {
            name: "S J (Unsplash)",
            url: "//unsplash.com/@sjjillan"
          }
        }}
        currentTopicSlug={topic.slug}
      />
    );
  }
});
