import { Grid } from "@mui/material";

import { MhcLocationFragment } from "graphqlApi/types";
import { BaseProps, TopicElementBaseRender } from "modules/Topics/types";

import { TopicLocationPicker } from "modules/Topics/components/TopicLocationPicker";

export const getLocationPickerElement = <
  P extends BaseProps & { locations: MhcLocationFragment[] }
>(
  id: string
): TopicElementBaseRender<P> => ({
  id,
  render: ({ location, locations = [] }) =>
    location &&
    locations?.length > 1 && (
      <Grid container>
        <Grid item xs={12} md={8} lg={4}>
          <TopicLocationPicker currentLocation={location} locations={locations} />
        </Grid>
      </Grid>
    )
});
