import { GetPageHrefProps } from "layout/DashboardLayout/util/pageHref";

export type EcdcPageId =
  | "ecdc"
  | "ecdcOverview"
  | "ecdcHealth"
  | "ecdcEducation"
  | "ecdcJustice"
  | "ecdcWealth";

export const ecdcSections: Record<string, string> = {
  ecdcOverview: "community-overview",
  ecdcHealth: "health",
  ecdcEducation: "education",
  ecdcJustice: "criminal-justice",
  ecdcWealth: "wealth-creation"
};

const getPath = (pageId: string, locationId = "", districtId = "") => {
  switch (pageId) {
    case "ecdc":
      return locationId === "state" ? "/" : `/locations/${locationId}`;
    case "ecdcEducation":
      return `/locations/${locationId}/education/${districtId === "state" ? "" : districtId}`;
    default:
      return `/locations/${locationId}/${ecdcSections[pageId] ?? ""}`;
  }
};

const ecdcBase = "/portals/ecdc";
export const ecdcPagePath = ({
  pageId,
  locationId = "state",
  districtId,
  anchorId
}: GetPageHrefProps): string => {
  const anchor = anchorId ? `#${anchorId}` : "";
  const path = getPath(pageId, locationId, districtId);
  return `${ecdcBase}${path}${anchor}`;
};
