"use client";

import { Box, Grid, Link as MuiLink, Typography, TypographyProps } from "@mui/material";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { StyledList } from "common/components/lists/StyledList";
import { TopicOverviewInsetCard } from "modules/Topics/components/TopicOverviewInsetCard";

const MentalHealthSubstanceUseOverview = () => {
  const spacing = DEFAULT_COLUMN_SPACING / 2;
  const titleProps: TypographyProps = { fontWeight: 700, variant: "h5" };
  const isMobile = useIsMobile();
  return (
    <Grid container spacing={DEFAULT_COLUMN_SPACING}>
      <Grid item sm={12} lg={5}>
        <ContentCardSectionTitle title="Introduction" />
        <ContentCardTextBlock
          title="How are Mental Health and Substance Use Disorder Connected?"
          titleProps={titleProps}
          sx={{ mb: isMobile ? 3 : undefined }}
        >
          <Typography mb={spacing}>
            According to the National Institute of Drug Abuse, &quot;many individuals who develop
            substance use disorders (SUD) are also diagnosed with mental disorders, and vice versa.
            Multiple national population surveys have found that about half of those who experience
            a mental illness during their lives will also experience a substance use disorder and
            vice versa.&quot;<sup>1</sup>
          </Typography>
        </ContentCardTextBlock>

        <ContentCardTextBlock
          title="Why is the SUD crisis important to Delawareans?"
          titleProps={titleProps}
          sx={{ mb: isMobile ? 3 : undefined }}
        >
          <Typography mb={spacing}>
            By drug overdose death rates alone, the substance use disorder crisis continues to
            escalate. The rate of drug overdose deaths has increased from 6.7 per 100,000 to 48.4
            per 100,000 in 2019, which is a seven-fold increase. Delaware has the second highest
            drug overdose death rate in the country with an age-adjusted drug overdose mortality
            rate of 48.4 per 100,000 in 2019. All three counties have rates higher than the national
            rate.
          </Typography>
        </ContentCardTextBlock>

        <ContentCardTextBlock
          title="What is Delaware doing to address the crisis?"
          titleProps={titleProps}
        >
          <Typography mb={spacing}>
            Delaware&apos;s monthly prescription rate of 33 per 1,000 persons in December 2019 was
            above the national average, but rates have been declining. Beginning with the launch of
            the Prescription Monitoring Program in 2012, Delaware has been working to address this
            crisis. Since then &quot;Help is Here&quot; was launched, community access to naloxone
            has been expanded, legislation to continue to support these efforts was passed,
            life-saving information has been distributed to the public through the OpiRescue App,
            and surveillance report data has been released to the public to further highlight the
            continued need for action.
          </Typography>
        </ContentCardTextBlock>
      </Grid>
      <Grid item sm={12} lg={7}>
        <ContentCardSectionTitle title="Finding Support" />
        <TopicOverviewInsetCard
          title="In a mental health crisis support is here."
          overline="Mental Heath Support"
          variant="emphasis"
          sx={{ mb: spacing }}
        >
          <Typography mb={spacing / 2}>
            <Typography component="span" fontWeight={600} display="block">
              For anonymous conversation:{" "}
            </Typography>
            Text <strong>DE</strong> to{" "}
            <MuiLink href="tel:741741" target="_blank">
              741741
            </MuiLink>{" "}
            for the Crisis Text Line. Call:{" "}
            <MuiLink href="tel:1-800-273-8255" target="_blank">
              1-800-273-TALK (8255)
            </MuiLink>{" "}
            to National Suicide Prevention Lifeline
          </Typography>
          <Typography>
            <Typography component="span" fontWeight={600} display="block">
              For in-person evaluation:{" "}
            </Typography>
            If under 18, call:{" "}
            <MuiLink href="tel:1-800-969-4357" target="_blank">
              1-800-969-HELP (4357)
            </MuiLink>{" "}
            If over 18, call:{" "}
            <MuiLink href="tel:1-800-652-2929" target="_blank">
              1-800-652-2929
            </MuiLink>{" "}
            in New Castle{" "}
            <MuiLink href="tel:1-800-345-6785" target="_blank">
              1-800-969-4357
            </MuiLink>{" "}
            in Kent/Sussex
          </Typography>
        </TopicOverviewInsetCard>
        <TopicOverviewInsetCard
          title="Are you or a loved one looking for substance abuse treatment?"
          overline="Substance Use Disorder Support"
          variant="primary"
        >
          <Typography mb={1}>Delaware has resources available.</Typography>
          <StyledList>
            <Box component="li">
              <MuiLink
                href="//opioidepidemic.maps.arcgis.com/apps/webappviewer/index.html?id=153b0c32fefc432eae6a0e8439b9f56b"
                target="_blank"
              >
                View the map of providers
              </MuiLink>
            </Box>
            <Box component="li">
              <MuiLink href="//www.helpisherede.com/" target="_blank">
                Visit Help is Here Delaware for support
              </MuiLink>
            </Box>
            <Box component="li">
              Call{" "}
              <MuiLink href="tel:833-9-467333" target="_blank">
                (833) 9-HOPEDE
              </MuiLink>{" "}
              to get the help you need
            </Box>
          </StyledList>
        </TopicOverviewInsetCard>
      </Grid>
    </Grid>
  );
};

export default MentalHealthSubstanceUseOverview;
