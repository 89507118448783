"use client";

import { StatAttributions } from "../../util/elementHelpers/dashboard/types";
import { MhcResourceFragment } from "graphqlApi/types";

import { getAdditionalInfo } from "../../content/additionalInfo";
import DefaultAdditionalInfo from "../../content/additionalInfo/DefaultAdditionalInfo";
import { TopicDictionaryKey } from "app/topics/util/config/pageDictionary";

import { CardLoadingIndicator } from "layout/card/CardLoadingIndicator";
import { ContentCard } from "layout/card/ContentCard";
import { useNavigationStore } from "common/state/useNavigationStore";

interface Props {
  topicSlug: string | TopicDictionaryKey;
  subtitle?: string;
  attributions?: StatAttributions;
  resources?: MhcResourceFragment[];
}

export const TopicAdditionalInfo: React.FC<Props> = ({
  topicSlug,
  attributions = [],
  resources = [],
  subtitle
}) => {
  const isNavigating = useNavigationStore((store) => store.isNavigating);
  const AdditionalInformationComponent = getAdditionalInfo(topicSlug as TopicDictionaryKey);
  return (
    <ContentCard
      id="additional-information"
      title="Additional Information"
      sx={{ position: "relative" }}
      subtitle={subtitle}
    >
      {isNavigating && <CardLoadingIndicator />}
      {AdditionalInformationComponent && (
        <AdditionalInformationComponent attributions={attributions} resources={resources ?? null} />
      )}
      {!AdditionalInformationComponent && (
        <DefaultAdditionalInfo attributions={attributions ?? []} resources={resources} />
      )}
    </ContentCard>
  );
};
// TODO: delete
