/* eslint-disable max-lines */

import { Box, Stack } from "@mui/material";
import every from "lodash/every";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";

import { STAT_IDENTIFIER_TYPES } from "graphqlApi/client/mhcClient/statIdentifier/constants";

import { DateFromApi } from "graphqlApi/customTypes";
import { StatAttributions, TopicDashboardChartGroup } from "./dashboard/types";
import { ChartSeriesOptions } from "common/components/charts/Chart/types";
import {
  BaseInvestigateChartProps,
  DatasetGroup,
  DefaultSeries,
  DropdownOption
} from "common/components/charts/Investigate/types";
import { MhcStatUnitEnum } from "common/util/types";
import { MhcStatIdentifier, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";
import { BaseProps, TopicElementBaseRender } from "modules/Topics/types";

import { getEmergencyAlertElement } from "./util/getEmergencyAlertElement";
import { getLabelInLocation } from "./util/getLabelInLocation";
import { getLocationName } from "./util/getLocationName";
import { getOverviewElement } from "./util/getOverviewElement";
import { getStoriesElement } from "./util/getStoriesElement";
import { seriesForLineChart } from "common/components/charts/util/series";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";
import { statTypeName } from "common/util/formatHelpers/statTypeName";
import { stratumGroupName } from "common/util/formatHelpers/stratumGroupHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";
import { xAxisLabelFormatterByGranularity } from "common/util/xAxisLabelFormatterByGranularity";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { TopicAdditionalInfo } from "../../components/TopicAdditionalInfo";
import TopicChildrenCard from "../../components/TopicChildrenCard";
import { TopicDashboardData } from "../../components/TopicDashboardData";
import TopicLocationComparisonChart from "../../components/TopicLocationComparisonChart";
import { InvestigateChart } from "common/components/charts/Investigate/InvestigateChart";
import ReportDates from "common/components/ReportDates/ReportDates";
import { TopicCard } from "modules/Topics/components/TopicCard";
import { TopicTreatmentProviders } from "modules/Topics/components/TopicTreatmentProviders";
import { TopicVideos, TopicVideosProps } from "modules/Topics/components/TopicVideos";
import {
  getLoadedStatDictionaryForLocation,
  LoadedLocationStatDictionaryByLocation,
  LoadedStat,
  LoadedStatDictionary
} from "../fetchingFunctions/fetchStatsForAllSections";
import { KpiElementProps } from "./kpis";
export {
  getEmergencyAlertElement,
  getLabelInLocation,
  getLocationName,
  getOverviewElement,
  getStoriesElement
};

export interface ChartsElementProps extends BaseProps {
  loadedStatDictionary?: LoadedLocationStatDictionaryByLocation;
}

export type GetChartsElementType = ChartsElementProps & {
  latestDate?: DateFromApi;
  updatedDate?: DateFromApi;
  granularity?: MhcTimeSeriesGranularityEnum;
  useRangeSelector?: boolean;
  useRangeSelectorButtons?: boolean;
  chartGroup?: TopicDashboardChartGroup | null | undefined;
};

export type GroupWithType = DatasetGroup &
  Pick<MhcStatIdentifier, "statType" | "ageAdjusted" | "isPercent">;
/**
 * Checks if groups have unique titles, if not, appends the stat type to the title
 *
 * @param groups
 *
 * @returns Modified groups with unique titles
 */
const checkGroupsForUniqueTitles = (groups: GroupWithType[]): DatasetGroup[] => {
  const groupedByTitle: Record<string, GroupWithType[]> = groupBy(groups, "groupTitle");
  if (every(Object.values(groupedByTitle), (v) => v.length === 1)) {
    return groups.map(({ statType, isPercent, ageAdjusted, ...group }) => group);
  }

  return Object.entries(groupedByTitle).flatMap(([_id, groups]) => {
    if (groups.length === 1) return groups;

    return groups.map(({ statType, isPercent, ageAdjusted, groupTitle, ...group }) => ({
      ...group,
      statType,
      isPercent,
      ageAdjusted,
      groupTitle: `${groupTitle} (${statTypeName({ statType, isPercent, ageAdjusted })})`
    }));
  });
};

export const getChartConfiguration = ({
  chartableStatDictionary
}: {
  chartableStatDictionary: LoadedStatDictionary;
}) => {
  // Create a list of stats sorted by identifier type
  const identifierMap: Array<[string, LoadedStat]> = sortBy(
    Object.entries(chartableStatDictionary),
    ([id]) => {
      const match = id.match(new RegExp(STAT_IDENTIFIER_TYPES.join("|")));
      return match ? STAT_IDENTIFIER_TYPES.indexOf(match[0]) : -1;
    }
  );
  const firstStatIdentifier = identifierMap?.[0]?.[1]?.statIdentifier;
  const attributions = identifierMap.flatMap(
    ([
      _id,
      {
        statIdentifier: { attributions = [] }
      }
    ]) => attributions
  );
  let defaultSeries: DefaultSeries | null = null;

  const investigateChartGroups: GroupWithType[] = identifierMap.map(
    ([_id, { stratifications = [], statIdentifier, timeSeries }], index) => {
      defaultSeries = {
        title: statIdentifier.name,
        series: seriesForLineChart({
          series: [
            {
              dates: timeSeries?.dates ?? [],
              values: timeSeries?.values ?? [],
              name: statIdentifier.name,
              valueOptions: getValueOptionsFromSi(firstStatIdentifier as MhcStatIdentifier),
              valueLabel: firstStatIdentifier?.subtitle ?? ""
            }
          ]
        }) as ChartSeriesOptions[]
      };

      let groupsOptions = {};
      if (stratifications.length) {
        const groupedStratifications = groupBy(
          stratifications,
          ({ statIdentifier }) => statIdentifier?.stratumGroup
        );

        groupsOptions = Object.entries(groupedStratifications).reduce(
          (options, [groupName, group]) => {
            const sortedStats = sortBy(group, "statIdentifier.stratumGroupOrder");
            const statIdentifier = sortedStats[0]?.statIdentifier as MhcStatIdentifier;
            const stratumGroup = statIdentifier.stratumGroup;
            options[groupName] = {
              title: stratumGroup ? stratumGroupName(stratumGroup) : "",
              series: seriesForLineChart({
                series: sortedStats.map(
                  ({ statIdentifier: { name, statCaption }, timeSeries }) => ({
                    name,
                    statCaption,
                    dates: timeSeries?.dates ?? [],
                    values: timeSeries?.values ?? []
                  })
                ),
                isAgeSeries: groupName.toLowerCase().includes("age")
              }),
              valueOptions: getValueOptionsFromSi(statIdentifier as MhcStatIdentifier),
              valueLabel: statIdentifier?.subtitle ?? "",
              statIdentifier
            } as DropdownOption;
            return options;
          },
          {} as Record<string, DropdownOption>
        ); // @see  DatasetGroup["options"])
      }

      return {
        default: index === 0,
        groupTitle: statIdentifier.name,
        isPercent: statIdentifier.isPercent,
        statType: statIdentifier.statType,
        ageAdjusted: statIdentifier.ageAdjusted,
        subtitle: statIdentifier.subtitle ?? null,
        options: groupsOptions,
        defaultSeries
      };
    }
  );

  return {
    firstStatIdentifier,
    attributions,
    investigateChartGroups: checkGroupsForUniqueTitles(investigateChartGroups),
    defaultSeries: defaultSeries as DefaultSeries | null
  };
};

export const getInvestigateChartElement = <P extends GetChartsElementType>(
  id: string,
  chartProps: Partial<BaseInvestigateChartProps> = {},
  options = { showLocationComparisonChart: false }
): TopicElementBaseRender<P> => ({
  id,
  render: (props) => {
    const {
      topic,
      location,
      loadedStatDictionary = {},
      granularity,
      latestDate,
      updatedDate,
      useRangeSelector,
      useRangeSelectorButtons,
      chartGroup
    } = props;

    if (props.locationNotAvailable || loadedStatDictionary === undefined) {
      return null;
    }

    const { showLocationComparisonChart } = options;
    const { id: locationId } = location;

    // Get loaded stats for the location
    const dictionaryForLocation = getLoadedStatDictionaryForLocation(
      location.id,
      loadedStatDictionary
    );

    // Remove stats that don't have time series
    Object.entries(dictionaryForLocation).forEach(([id, stat]) => {
      if (!("timeSeries" in stat)) {
        delete dictionaryForLocation[id];
      }
    });

    // Get chart configuration (eg groups from stratifications, default series, etc.)
    const { firstStatIdentifier, attributions, investigateChartGroups, defaultSeries } =
      getChartConfiguration({ chartableStatDictionary: dictionaryForLocation });

    let locationComparisonTitle = "";
    if (showLocationComparisonChart) {
      locationComparisonTitle = "Location Comparison";
      firstStatIdentifier?.name &&
        (locationComparisonTitle = `${firstStatIdentifier?.name} by County`);
    }

    return (
      <TopicCard
        title="Investigate Over Time"
        subtitle={getLabelInLocation(props.topic?.name || "", location?.name)}
        headerChildren={
          <ReportDates
            granularity={granularity}
            dataDate={getUtcDateFromString(latestDate) ?? undefined}
            updatedDate={getUtcDateFromString(updatedDate) ?? undefined}
          />
        }
        sx={{ gap: 4 }}
      >
        <Stack gap={10}>
          {showLocationComparisonChart && (
            <Box>
              <ContentCardSectionTitle title="County Comparison" />
              <TopicLocationComparisonChart
                locationId={locationId}
                locationName={location.name}
                title={locationComparisonTitle}
                loadedStatDictionary={loadedStatDictionary}
                topicName={topic?.name}
              />
            </Box>
          )}
          <Box>
            {showLocationComparisonChart && <ContentCardSectionTitle title="Data Stratification" />}
            {isNil(chartGroup) && (
              <InvestigateChart
                title={props.topic?.name}
                locationName={getLocationName(location)}
                granularity={granularity || undefined}
                timeScale={granularity === "year" ? "yearly" : undefined}
                unit={firstStatIdentifier?.unit || undefined}
                isPercent={firstStatIdentifier?.unit === MhcStatUnitEnum.Percent}
                xAxisTitle={granularity ? xAxisLabelFormatterByGranularity(granularity) : "Date"}
                groups={investigateChartGroups}
                precision={firstStatIdentifier?.precision}
                useRangeSelector={useRangeSelector}
                useRangeSelectorButtons={useRangeSelectorButtons}
                useNavigator={false}
                useScrollbar={false}
                suppressionNote="Please note: Counts of less than 11 are suppressed. If the population is sufficiently large, 0 counts may be shown. Rates based on counts less than 20 may be unstable."
                attributions={(attributions as BaseInvestigateChartProps["attributions"]) ?? []}
                defaultSeries={defaultSeries}
                {...chartProps}
              />
            )}
            {!isNil(chartGroup) && (
              <TopicDashboardData
                section={{ id, chartGroup }}
                key={id}
                locationName={location.name}
                currentTopicSlug={topic?.slug}
              />
            )}
          </Box>
        </Stack>
      </TopicCard>
    );
  }
});

export const getAdditionalInfoElement = <P extends BaseProps & { attributions?: StatAttributions }>(
  id: string
): TopicElementBaseRender<P> => ({
  id,
  render: ({ topic, location, attributions = [], locationNotAvailable }) => {
    if (locationNotAvailable) {
      return null;
    }
    return (
      topic && (
        <TopicAdditionalInfo
          topicSlug={topic.slug}
          attributions={attributions}
          resources={topic.resources}
          subtitle={getLabelInLocation(topic.name || "", location.name)}
        />
      )
    );
  }
}); //TODO: delete

export const getTreatmentProvidersElement = (id: string): TopicElementBaseRender<BaseProps> => ({
  id,
  render: ({ locationNotAvailable }) => (locationNotAvailable ? null : <TopicTreatmentProviders />)
});

export const getVideoComponent = (id: string): TopicElementBaseRender<TopicVideosProps> => ({
  id,
  render: ({ media, locationNotAvailable, location }) =>
    !locationNotAvailable && media?.length > 0 && <TopicVideos location={location} media={media} />
});

export const getTopicChildrenCard = <
  P extends KpiElementProps & {
    latestDate?: DateFromApi;
    updatedDate?: DateFromApi;
    granularity: MhcTimeSeriesGranularityEnum;
  }
>(
  id: string
): TopicElementBaseRender<P> => ({
  id,
  render: ({
    topic,
    kpis,
    location,
    statIds,
    statIdTopicInfoDictionary,
    latestDate,
    updatedDate,
    granularity,
    locationNotAvailable
  }) => {
    return (
      !locationNotAvailable && (
        <TopicChildrenCard
          kpis={kpis}
          topic={topic}
          subtitle={getLabelInLocation(topic?.name, location?.name)}
          location={location}
          statIds={statIds}
          statIdTopicInfoDictionary={statIdTopicInfoDictionary}
          dates={{
            dataDate: latestDate ? new Date(latestDate) : undefined,
            updatedDate: updatedDate ? new Date(updatedDate) : undefined
          }}
          granularity={granularity}
        />
      )
    );
  }
});
