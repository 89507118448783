"use client";

import { useCallback } from "react";
import { useRouter } from "next/navigation";
import { useSetRecoilState } from "recoil";

import { MhcLocationFragment } from "graphqlApi/types";

import useRouterPath from "common/util/hooks/usePathWithParamKeys";

import { currentLocationAtom } from "common/atoms/currentLocationAtom";
import { useNavigationStore } from "common/state/useNavigationStore";
import {
  LocationPicker,
  LocationPickerProps
} from "common/components/LocationPicker/LocationPicker";

interface Props extends Pick<LocationPickerProps, "locations" | "currentLocation"> {
  label?: string;
}

export const TopicLocationPicker: React.FC<Props> = ({
  locations,
  currentLocation,
  label = "Selected report location"
}) => {
  const router = useRouter();
  const { params, pathWithParamKeys } = useRouterPath();
  const setIsNavigating = useNavigationStore((store) => store.setIsNavigating);
  const currentLocationSetter = useSetRecoilState(currentLocationAtom);
  const onChange = useCallback(
    (location: MhcLocationFragment) => {
      currentLocationSetter(location);
      setIsNavigating(true);
      let newPath = pathWithParamKeys.replace("[locationId]", location.id);
      const _params = { ...params };
      delete _params.locationId;
      Object.entries(_params).forEach(([key, value]) => {
        newPath = newPath.replace(`[${key}]`, value as string);
      });
      router.push(newPath);
    },
    [setIsNavigating, currentLocationSetter, router, pathWithParamKeys, params]
  );
  return (
    <LocationPicker
      groupLocations
      label={label}
      sx={{ mt: 0, mb: 0 }}
      locations={locations}
      currentLocation={currentLocation}
      onChange={onChange}
    />
  );
};
