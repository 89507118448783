import { SvgIcon } from "@mui/material";
import communityImage from "public/images/community.jpg";
import justiceImage from "public/images/criminalJustice.jpg";
import educationImage from "public/images/education.jpg";
import healthImage from "public/images/health.jpg";
import wealthImage from "public/images/wealthCreation.jpg";

import { EcdcPageId } from "modules/ECDC/util/ecdcPageRoutes";

import {
  ecdcEdcuationPageSections,
  ecdcHealthcareSections,
  ecdcJusticeSections,
  ecdcOverviewSections,
  EcdcPageSection,
  ecdcWealthSections
} from "./PageSections";

export interface EcdcPage {
  id: EcdcPageId;
  name: string;
  tagLine?: string;
  about?: string;
  description?: string;
  shortDescription?: string;
  icon?: typeof SvgIcon;
  sections: Array<EcdcPageSection>;
  image?: string | StaticImageData;
  imageAlt?: string;
}

export interface MhcPage {
  slug: string;
  name: string;
  description?: string;
}

export const ecdcPage: EcdcPage = {
  id: "ecdc",
  name: "Overview",
  sections: []
};

export const ecdcSubPagesMap: Record<string, EcdcPage> = {
  ecdcOverview: {
    id: "ecdcOverview",
    name: "Population Data",
    description: `
      Disparities and gaps frequently reveal themselves geographically, and
      efforts to address inequities benefit from local-scale data and strategies.
      The Data Center provides summary demographics at the state, county, census tract and zip code levels.
    `,
    about: `
      The tables and maps below present basic figures about Delaware's population at
      the state and Zip code levels. In the context of equity, information about
      population totals and population density is useful for understanding the scope
      and scale of the disparities captured under each of the Data Center's equity
      pillars. Note that additional, detailed demographic data is available by selecting
      specific Zip codes from the location selector above."
    `,
    sections: ecdcOverviewSections,
    image: communityImage,
    imageAlt: "People walking on sidewalk",
    shortDescription: "Demographics, population data and other community details."
  },
  ecdcHealth: {
    id: "ecdcHealth",
    name: "Health",
    tagLine: "A range of indicators for exploring gaps and inequities in health.",
    description: `
      Disparities in health outcomes—such as life expectancy and rates of
      diabetes—frequently correlate with unequal treatment based on race and
      other identities. The Data Center gathers a range of indicators for
      exploring gaps and inequities in health.
    `,
    about: `
      Regular physicals, screenings, vaccinations, and other preventative health measures
      are critical to preventing disease and disability. Disparities in preventative
      healthcare can be indicative of inequities in healthcare access, differing environmental
      exposures (e.g. local air quality), service gaps, and other systemic factors potentially
      linked to race, gender, and other identities.
    `,
    sections: ecdcHealthcareSections,
    image: healthImage,
    imageAlt: "Health professionals examining an x-ray",
    shortDescription:
      "Barriers to preventative care and health insurance coverage can lead to adverse chronic health outcomes."
  },
  ecdcEducation: {
    id: "ecdcEducation",
    name: "Education",
    tagLine: "Math and reading assessments, graduation rates, and other education indicators.",
    description: `
      From preschool to high school and beyond, measures of enrollment
      and proficiency indicate inequities in educational access and achievement.
      The Data Center disaggregates data covering math and English language
      arts assessments, graduation rates, and other indicators.
    `,
    sections: ecdcEdcuationPageSections,
    image: educationImage,
    imageAlt: "Child working on art project",
    shortDescription:
      "People with higher levels of education are more likely to be healthier and live longer. Social and economic factors can affect academic success."
  },
  ecdcJustice: {
    id: "ecdcJustice",
    name: "Criminal Justice",
    tagLine: "Data that help illustrate disparities related to the criminal justice system.",
    description: `
      Inequities in the interaction with and treatment by the criminal justice
      system are among the most widely discussed equity issues in the U.S. today.
      The Data Center presents data that helps illustrate disparities related to
      criminal justice.
    `,
    sections: ecdcJusticeSections,
    image: justiceImage,
    imageAlt: "Scales of justice on lawyers desk",
    shortDescription:
      "Student high-risk behaviors put them and their communities at risk for physical, behavioral, & mental health problems."
  },
  ecdcWealth: {
    id: "ecdcWealth",
    name: "Wealth Creation",
    tagLine: "Income, vulnerability indices, unemployment, housing, and poverty rates.",
    description: `
      Racism and other patterns of discrimination have long constrained opportunity
      for some and privileged others. The Data Center's indicators of economic
      status and mobility include income figures, vulnerability indices, and unemployment, housing, and poverty rates.
    `,
    sections: ecdcWealthSections,
    image: wealthImage,
    imageAlt: "Person sitting at desk",
    shortDescription:
      "Many people cannot afford basic needs like healthy foods, health care, and housing even while working a stable job."
  }
};

export const ecdcSubPages: EcdcPage[] = Object.values(ecdcSubPagesMap);

export const mhcSubPages: MhcPage[] = [];

export const ecdcPages: EcdcPage[] = [ecdcPage].concat(ecdcSubPages);

export const findEcdcPage = (id: EcdcPageId): EcdcPage => {
  const found = ecdcPages.find((page) => page.id === id);
  if (!found) {
    throw new Error(`not found ${id}`);
  }
  return found;
};
