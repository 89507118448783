import { getOverviewContent } from "../content/overview";
import { TopicDictionaryKey } from "app/topics/util/config/pageDictionary";

import { ContentCard } from "layout/card/ContentCard";
import ReportDates, { ReportDatesProps } from "common/components/ReportDates/ReportDates";

export interface TopicOverviewProps {
  dates?: ReportDatesProps;
  topicSlug: string | TopicDictionaryKey;
  topicDescription?: string | null;
}

export const TopicOverview: React.FC<TopicOverviewProps> = ({
  dates,
  topicSlug,
  topicDescription
}) => {
  const OverviewComponent = getOverviewContent(topicSlug as TopicDictionaryKey);
  if (OverviewComponent.type === "default" && !topicDescription) {
    return null;
  }
  return (
    <ContentCard
      title="Overview"
      id="overview"
      sx={{ mb: 0 }}
      headerChildren={dates ? <ReportDates {...dates} /> : undefined}
    >
      <OverviewComponent.component description={topicDescription} />
    </ContentCard>
  );
};
// TODO: Delete
