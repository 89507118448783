import {
  StatAttributions,
  TopicDashboardImages,
  TopicDashboardSectionProps
} from "../util/elementHelpers/dashboard/types";
import { MhcLocationFragment, MhcLocationWithoutGeoJsonFragment } from "graphqlApi/types";
import { BaseProps, TopicConfig } from "modules/Topics/types";

import { getLocationPickerElement } from "../util/elementHelpers/dashboard";
import { getAdditionalInfoElement } from "modules/Topics/util/elementHelpers";

import { PageSection } from "layout/Breadcrumb/TopicBreadCrumbs";

export interface DefaultTopicDashboardConfigProps extends BaseProps {
  images?: TopicDashboardImages;
  sections?: TopicDashboardSectionProps[];
  locations: (MhcLocationFragment | MhcLocationWithoutGeoJsonFragment)[];
  attributions?: StatAttributions;
  pageSections?: PageSection[];
  statIds?: string[];
}
export const defaultTopicDashboardConfig: TopicConfig<DefaultTopicDashboardConfigProps> = {
  name: "topic-dashboard-page",
  getData: async (_params, baseProps) => {
    return await {
      ...baseProps,
      locations: [],
      attributions: []
    };
  },
  elements: [getLocationPickerElement("locationPicker"), getAdditionalInfoElement("additionalInfo")]
};
// TODO: delete
