import { FetchKpiOptions } from "../../util/fetchingFunctions/kpi/types";
import { TopicDictionary } from "app/topics/util/types";

export const kpiOptionsByTopic: TopicDictionary<FetchKpiOptions> = {};
// TODO: delete

export const kpiTitleOverrides: Record<string, string> = {
  COPD_CDC_PLACES_CRUDE_RATE: "COPD Prevalence"
};
// TODO: delete
