"use client";

import { Typography } from "@mui/material";
import pick from "lodash/pick";
import introImage from "public/images/topics/lead/cdc-voVYCm6xoQo-unsplash.jpg";

import { TopicDashboardSectionProps } from "../../dashboard/types";
import { TopicElementBaseRender } from "modules/Topics/types";

import useRouterPath from "common/util/hooks/usePathWithParamKeys";
import { getTopicDashboardSection } from "modules/Topics/util/getTopicDashboardSection";
import { updateBivariateSections } from "modules/Topics/util/sectionHelpers";

import { TopicDashboardSection } from "modules/Topics/components/TopicDashboardSection";
import { getLocationName } from "../..";

export const getVulerableCommunitiesElement = <
  P extends { sections?: TopicDashboardSectionProps[] }
>(
  id?: string
): TopicElementBaseRender<P> => ({
  id: id || "testing-coverage",
  render: ({ topic, sections, location }) => {
    // ES Lint doesn't know this is essentially a component render function
    // so it complains about useRouter being called outside of that context
    // eslint-disable-next-line
    const { pathWithParamKeys, params } = useRouterPath();
    if (!topic) return null;

    const { subSections, ...props } = getTopicDashboardSection(sections ?? [], "testing-coverage");

    const intro = (
      <>
        <Typography>
          Combining information about a community&apos;s general vulnerability to the harms of
          disasters — like disease outbreaks and natural events — with current lead testing coverage
          (% of population under 6 years old tested) can help support and prioritize testing
          efforts.
        </Typography>
        <Typography>
          This map provides such an assessment, depicting CDC social vulnerability data with local
          testing coverage — dark blue regions indicate low testing for lead in more vulnerable
          communities; lighter shades of blue indicate more widely tested populations.
        </Typography>
        <Typography fontStyle="italic">
          All values for percent of children tested are 5-year averages.
        </Typography>
      </>
    );

    return (
      <TopicDashboardSection
        {...props}
        intro={intro}
        subSections={updateBivariateSections({
          subSections,
          location: pick(location, ["id", "name", "geography"]),
          pathWithParamKeys,
          params
        })}
        locationName={getLocationName(location)}
        image={{
          src: introImage,
          alt: "Child receiving vaccination or blood test",
          credit: {
            name: "CDC",
            url: "//unsplash.com/@cdc"
          }
        }}
        currentTopicSlug={topic.slug}
      />
    );
  }
});
