import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "@mui/material";
import isNil from "lodash/isNil";

import {
  TopicDashboardSectionConfig,
  TopicDashboardSubSectionData
} from "./elementHelpers/dashboard/types";
import { MhcGeographyEnum, MhcLocationFragment } from "graphqlApi/types";

import { bivariateDefaultFeatureColorFunctionBuilder } from "common/components/BivariateSection/util/bivariateDefaultFeatureColorFunctionBuilder";
import { DEFAULT_BIVARIATE_HEATMAP_COLORS } from "common/components/BivariateSection/util/colors";
import { updateLocationOnCurrentPath } from "common/components/LocationSwitcher/util/updateLocationOnCurrentPath";

import { PageSection } from "layout/Breadcrumb/TopicBreadCrumbs";
import { InitialDemographicInvestigateChartProps } from "./elementHelpers/Covid/common/components/DemographicInvestigateChart";
import { BivariateSectionProps } from "common/components/BivariateSection/BivariateSection";
import { LineChartProps, LineChartSeries } from "common/components/charts/LineChart";

interface Params {
  location?: Pick<MhcLocationFragment, "id" | "name" | "geography">;
  subSections: TopicDashboardSubSectionData[];
  params: Record<string, string | string[]>;
  pathWithParamKeys: string;
}

export const updateBivariateSections = ({
  location,
  subSections,
  params,
  pathWithParamKeys
}: Params) => {
  // Add callbacks/functions not parsable into JSON
  const sectionsWithBivariateAnalysis = subSections.filter((s) => "bivariateAnalysis" in s);
  sectionsWithBivariateAnalysis.forEach((s) => {
    const sectionIsSVI = s.bivariateAnalysis?.props?.bivariateValueComparisonName?.includes("SVI");
    if (!s.bivariateAnalysis?.props) return;
    const featureColorFunction = bivariateDefaultFeatureColorFunctionBuilder(
      s.bivariateAnalysis.props.squareProps.xCategories,
      s.bivariateAnalysis.props.squareProps.yCategories,
      DEFAULT_BIVARIATE_HEATMAP_COLORS
    );

    // Update Slider props (if they exists) to render with min/max based on data
    if (
      !sectionIsSVI &&
      "sviSliderProps" in s.bivariateAnalysis.props &&
      !isNil(s.bivariateAnalysis.props.sviSliderProps)
    ) {
      s.bivariateAnalysis.props.sviSliderProps = {
        ...s.bivariateAnalysis.props.sviSliderProps,
        rightLabel: (s.bivariateAnalysis.props.squareProps.yAxisRange?.max as string) ?? "100%",
        rightValue: s.bivariateAnalysis.props.squareProps.yAxisRange?.max
          ? parseInt(s.bivariateAnalysis.props.squareProps.yAxisRange?.max as string)
          : 100
      } as BivariateSectionProps["sviSliderProps"];
    }

    s.bivariateAnalysis.props["viewMoreInformationNode"] =
      location?.geography === MhcGeographyEnum.CensusTract && sectionIsSVI ? (
        <Link
          href={`/locations/${location.id}/topics/svi`}
          target="_blank"
          sx={{ display: "inline-flex", alignItems: "center" }}
        >
          <OpenInNewIcon fontSize="inherit" sx={{ mr: 0.5 }} />
          View more information about {location.name} SVI score
        </Link>
      ) : null;
    if (!isNil(s.bivariateAnalysis.props["mapProps"])) {
      s.bivariateAnalysis.props["mapProps"]["featureColorFunction"] = featureColorFunction;
      s.bivariateAnalysis.props["mapProps"]["mapPopoverButtonHrefCreator"] = (slug) => {
        return updateLocationOnCurrentPath({
          locationId: slug ?? location?.id ?? "state",
          pathname: pathWithParamKeys,
          params
        });
      };
    }
  });
  return subSections;
};

export const getFirstChartSeriesFromSection = (
  section?: TopicDashboardSubSectionData | null
): LineChartSeries | null => {
  if (!section) return null;
  // Get series from loaded data of suspectedSection
  const charts = section?.chartGroup?.charts ?? [];
  const chartsProps = (charts?.length && charts[0] && (charts[0].props as LineChartProps)) || {
    series: []
  };
  const { series = [] } = chartsProps;
  return (series && series.length && series[0]) || null;
};

export const getPageSections = (sections: TopicDashboardSectionConfig[]) =>
  sections.map(({ id, title }) => ({ id, name: title })) as PageSection[];

/**
 * Finds charts within chart groups within subsections
 *
 * @param sections - list of sections or subsections where a chartGroup is expected
 * @param _id - Optional id for the investigate chart section
 *
 * @returns list of props and attributions for each chart within chartGroups
 *
 * @see TopicDashboardSubSectionData
 *
 */
export const getInvestigateChartFromSubSections = (
  sections: TopicDashboardSubSectionData[],
  _id?: string
): InitialDemographicInvestigateChartProps | null => {
  const validateId = _id ?? "investigateChart";
  const section = sections?.find(({ id }) => id === validateId);
  if (!section) return null;
  return {
    props: section?.chartGroup?.charts ?? [],
    attributions: section?.attributions ?? []
  };
};
