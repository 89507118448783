import { EcdcPageSection } from "./types";
import { MhcStratumGroupEnum } from "graphqlApi/types";

import { ECDC_NAME } from "common/constants";

export const ecdcJusticeSections: Array<EcdcPageSection> = [
  {
    id: "youthRiskFactors",
    name: "Youth Risk Factors",
    about: `
      Data describing youth behavior and experiences related to weapons, violence,
      and the police are important not just for understanding current trends, but as
      indicators of potential future and increasingly serious interactions with the
      criminal justice system. Understanding discrepancies by race, sex, and age is
      key to identifying and rectifying both implicit biases and shortcomings in support.
    `,
    caption: `${ECDC_NAME} / Criminal Justice`,
    subSections: [
      {
        id: "ALL_SCHOOL_SURVEY_V2_CARRY_WEAPON_NOT_SCHOOL_EVER_CRUDE_RATE",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Grade
        ]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_TAKE_WEAPON_SCHOOL_EVER_CRUDE_RATE",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Grade
        ]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_TAKE_PART_FIGHT_PAST_YEAR_CRUDE_RATE",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Grade
        ]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_STOPPED_BY_POLICE_PAST_YEAR_CRUDE_RATE",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Grade
        ]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_ACES_PARENT_INCARCERATED_CRUDE_RATE",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Grade
        ]
      }
    ]
  }
];
