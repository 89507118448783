import { DashboardPage } from "layout/DashboardLayout/util/dashboardConfigurations";

export const fluSections = [
  "overview",
  "positive-cases",
  "hospitalizations",
  "vaccinations"
] as const;

export type FluSectionsType = (typeof fluSections)[number];

export interface FluSection extends Omit<DashboardPage, "locationId"> {
  id: FluSectionsType;
  slug: string;
  pageName: string; // Reduce naming conflicts with covid dashboard
}

export const FluSections: { [key in FluSectionsType]: FluSection } = {
  overview: {
    id: "overview",
    name: "Overview",
    slug: "overview",
    topicSlug: "overview",
    pageName: "Overview",
    href: ""
  },
  "positive-cases": {
    id: "positive-cases",
    pageName: "Flu Cases",
    name: "Cases",
    slug: "positive-cases",
    topicSlug: "positive-cases",
    href: ""
  },
  hospitalizations: {
    id: "hospitalizations",
    pageName: "Flu Hospitalizations",
    name: "Hospitalizations",
    slug: "hospitalizations",
    topicSlug: "hospitalizations",
    href: ""
  },
  vaccinations: {
    id: "vaccinations",
    pageName: "Flu Vaccinations",
    name: "Vaccinations",
    slug: "vaccinations",
    topicSlug: "vaccinations",
    href: ""
  }
};

export const FluSectionsArray = Object.values(FluSections);
