"use client";

import { Typography } from "@mui/material";
import leadPainImage from "public/images/topics/lead/lead_paint_1.jpg";

import { TopicDashboardSectionProps } from "../../dashboard/types";
import { TopicElementBaseRender } from "modules/Topics/types";

import { getTopicDashboardSection } from "modules/Topics/util/getTopicDashboardSection";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { TopicDashboardSection } from "modules/Topics/components/TopicDashboardSection";
import { TopicTableOfContents } from "modules/Topics/components/TopicTableOfContents";
import leadSections from "../sections";

export const getOverviewElement = <P extends { sections?: TopicDashboardSectionProps[] }>(
  id?: string
): TopicElementBaseRender<P> => ({
  id: id ?? "leadOverview",
  render: ({ topic, sections }) => {
    if (!topic) return null;

    const intro = (
      <>
        <ContentCardTextBlock
          title="What is lead poisoning? "
          sx={{ mb: 0 }}
          titleProps={{ fontWeight: 600, variant: "h6" }}
        ></ContentCardTextBlock>
        <Typography>
          Lead is a toxic metal that can harm the body, especially in young children and infants.
          Common sources of lead exposure include lead-based paint, contaminated soil, and certain
          consumer products. Symptoms of lead poisoning can include abdominal pain, headaches, and
          developmental delays. It is important to take steps to prevent lead exposure, especially
          in young children, as it can cause long-term health problems.
        </Typography>
      </>
    );

    return (
      <TopicDashboardSection
        {...getTopicDashboardSection(sections ?? [], "leadOverview")}
        intro={intro}
        image={{
          src: leadPainImage,
          alt: "Lead paint on an old window frame.",
          credit: {
            name: "US EPA",
            url: "//19january2017snapshot.epa.gov/newsreleases/us-epa-settles-anaheim-real-estate-firm-failure-disclose-risks-lead-based-paint_.html"
          }
        }}
        currentTopicSlug={topic.slug}
      >
        <ContentCardSubSection sx={{ mb: 4 }}>
          <ContentCardSectionTitle title="Lead Dashboard Sections" />
          <TopicTableOfContents sections={leadSections} removeFirstItem={false} />
        </ContentCardSubSection>
      </TopicDashboardSection>
    );
  }
});
