import compact from "lodash/compact";
import isNil from "lodash/isNil";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import {
  getLocationWithGeoJson,
  getMhcLocationDateSeries,
  getMhcLocationTimeSeries,
  getMhcStatIdentifier
} from "graphqlApi/legacy/mhcClient";

import {
  DateSeriesQuery,
  DateSeriesQueryVariables,
  MhcAttributionFragment,
  MhcTopicFragment,
  TimeSeriesQuery,
  TimeSeriesQueryVariables
} from "graphqlApi/types";
import { BaseProps, TopicPageParams, TopicPageTopic } from "modules/Topics/types";
import { StatIdTopicInfoDictionary } from "modules/Topics/util/fetchingFunctions/kpi/types";

import { defaultRevalidate } from "env";
import { logError } from "common/util/consoleHelpers";
import { loadSideNavItems } from "common/util/fetchTopics";
import { loadCurrentTopic } from "common/util/fetchTopics/legacy/fetchTopics";
import { getGroupsFromStatIdentifier } from "common/util/statIdentifierStratifications";

export const revalidate = defaultRevalidate();
export const redirect = (() => ({
  redirect: {
    destination: "/404",
    permanent: false
  },
  revalidate
}))();

type GetStatIdsForTopic = {
  topic?: MhcTopicFragment | undefined | null;
  useChildren?: boolean;
  includeRelated?: boolean;
};

export const getStatIdsForTopic = ({
  topic,
  useChildren = true,
  includeRelated = false
}: GetStatIdsForTopic) => {
  let statIds: string[] = [];
  const statIdTopicInfoDictionary: StatIdTopicInfoDictionary = {};
  if (!topic) return { statIds, statIdTopicInfoDictionary };
  if (topic.children?.length && useChildren) {
    statIds = compact(
      (topic.children || []).map((child) => {
        if (!child.statIdentifiers?.length) return null;
        const id = child.statIdentifiers[0]?.id;
        if (!id) return null;
        statIdTopicInfoDictionary[id] = {
          path: child.href,
          name: child.name
        };
        return id;
      })
    );
  } else if (topic.statIdentifiers?.length && !useChildren) {
    statIds = topic.statIdentifiers.flatMap(({ id }) => {
      let related: string[] = [];
      if (includeRelated) {
        related =
          topic.statIdentifiers?.flatMap(({ related }) => related?.map(({ id }) => id) ?? []) ?? [];
      }
      return [id, ...related];
    });
  }
  return { statIds: uniq(statIds), statIdTopicInfoDictionary };
};

export const getAttributionsForTopic = (topic?: MhcTopicFragment | undefined | null) => {
  if (!topic) return [] as MhcAttributionFragment[];
  return uniqBy(
    (topic?.statIdentifiers || []).map(({ attributions }) => attributions).flat(),
    "id"
  );
};

export const updateParent = async (topic: TopicPageTopic): Promise<TopicPageTopic> => {
  if (topic.parent) {
    const __topic = await loadCurrentTopic({ slug: topic.parent.slug });
    if (!isNil(__topic)) {
      const parentUpdated = {
        ...topic,
        parent: __topic
      };
      if (__topic.parent) {
        return {
          ...parentUpdated,
          parent: await updateParent(__topic)
        };
      } else {
        return parentUpdated;
      }
    }
  }
  return topic;
};

export const getBasePropsForParams = async ({
  topicId = "",
  locationId = "state"
}: TopicPageParams) => {
  try {
    const location = await getLocationWithGeoJson({ locationId });
    const sideNavItems = await loadSideNavItems(locationId);
    let topic = await loadCurrentTopic({ slug: topicId, locationId });
    topic = await updateParent(topic);
    return {
      sideNavItems,
      topic,
      location
    } as BaseProps;
  } catch (err) {
    logError(err);
    return null;
  }
};

interface GetTimeSeriesForStatIdsArgs {
  statIds: string[];
  args:
    | Omit<TimeSeriesQueryVariables, "statId" | "groups">
    | Omit<DateSeriesQueryVariables, "statId" | "groups">;
  version?: number;
  startsOn?: string | null;
  endsOn?: string | null;
}
export const getTimeSeriesForStatIds = async ({
  statIds,
  args,
  version = 1,
  startsOn,
  endsOn
}: GetTimeSeriesForStatIdsArgs) => {
  const result: Record<string, TimeSeriesQuery["location"] | DateSeriesQuery["location"]> = {};

  for (const statId of statIds) {
    const statIdentifier = await getMhcStatIdentifier({ statId });
    result[statId] = await (version > 1 ? getMhcLocationDateSeries : getMhcLocationTimeSeries)({
      ...args,
      groups: getGroupsFromStatIdentifier(statIdentifier),
      statId,
      startsOn,
      endsOn
    });
  }

  return result;
};
