"use client";

import { Typography } from "@mui/material";
import introImage from "public/images/topics/lead/young-girl-old-door.jpg";

import { TopicDashboardSectionProps } from "../../dashboard/types";
import { TopicElementBaseRender } from "modules/Topics/types";

import { getTopicDashboardSection } from "modules/Topics/util/getTopicDashboardSection";

import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { TopicDashboardSection } from "modules/Topics/components/TopicDashboardSection";
import { getLocationName } from "../..";

export const getTestResultsElement = <P extends { sections?: TopicDashboardSectionProps[] }>(
  id?: string
): TopicElementBaseRender<P> => ({
  id: id ?? "test-results",
  render: ({ topic, sections, location }) => {
    if (!topic) return null;

    const intro = (
      <>
        <ContentCardTextBlock
          title="Why do we test for lead poisoning?"
          sx={{ mb: 0 }}
          titleProps={{ fontWeight: 600, variant: "h6" }}
        ></ContentCardTextBlock>
        <Typography>
          Protecting children from exposure to lead is important to lifelong good health. No safe
          blood lead level in children has been identified. Even low levels of lead in blood have
          been shown to negatively affect a child&apos;s intelligence, ability to pay attention, and
          academic achievement. A blood lead test is the best way to determine if a child has lead
          poisoning. A child with lead poisoning may not have visible signs or symptoms. Many
          children who have lead poisoning look and act healthy. Parents can talk to their
          child&apos;s healthcare provider about getting a blood lead test if their child may have
          been exposed to lead.
        </Typography>

        <Typography>
          Parents should talk to their child&apos;s healthcare provider about whether their child
          needs to be tested for lead. The child&apos;s healthcare provider may ask questions to see
          if the child is at risk for lead poisoning. The best way to know if a child has been
          exposed to lead is to have their blood tested.
        </Typography>
      </>
    );

    return (
      <TopicDashboardSection
        {...getTopicDashboardSection(sections ?? [], "test-results")}
        intro={intro}
        locationName={getLocationName(location)}
        image={{
          src: introImage,
          alt: "Young child in front of door with chipping paint",
          credit: {
            name: "US EPA",
            url: "//www.cdc.gov/nceh/lead/overview.html"
          }
        }}
        currentTopicSlug={topic.slug}
      />
    );
  }
});
