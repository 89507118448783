import { EcdcPageSection } from "./types";
import { MhcStratumGroupEnum } from "graphqlApi/types";

import { ECDC_NAME } from "common/constants";

export const ecdcEdcuationPageSections: Array<EcdcPageSection> = [
  {
    id: "suspensionsAndExpulsions",
    name: "Suspensions and Expulsions",
    caption: `${ECDC_NAME} / Education`,
    about: `
      Rates of student suspension and expulsion can provide a window into potential
      disciplinary biases based on race and other identities. Though disciplinary
      actions are typically subject to district-by-district policies and procedures
      (and unique incident and student details), evidence of unequal suspension and
      expulsion decisions are relevant to discussions around alternative disciplinary
      measures.
    `,
    subSections: [
      {
        id: "SCHOOL_DISTRICT_IN_SCHOOL_SUSPENSION_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race
        ]
      },
      {
        id: "SCHOOL_DISTRICT_OUT_OF_SCHOOL_SUSPENSION_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race
        ]
      },
      {
        id: "SCHOOL_DISTRICT_EXPULSION_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race
        ]
      }
    ]
  },
  {
    id: "studentPerformance",
    name: "Student Performance Assessment",
    caption: `${ECDC_NAME} / Education`,
    subSections: [
      {
        id: "THIRD_GRADE_SCHOOL_DISTRICT_SBSA_ELA_PCTPROFICIENT_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ],
        aboutUrl: "https://data.delaware.gov/Education/Student-Assessment-Performance/ms6b-mt82"
      },
      {
        id: "EIGHTH_GRADE_SCHOOL_DISTRICT_SBSA_ELA_PCTPROFICIENT_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ],
        aboutUrl: "https://data.delaware.gov/Education/Student-Assessment-Performance/ms6b-mt82"
      },
      {
        id: "ELEVENTH_GRADE_SCHOOL_DISTRICT_SAT_ELA_PCTPROFICIENT_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ],
        aboutUrl: "https://data.delaware.gov/Education/Student-Assessment-Performance/ms6b-mt82"
      },
      {
        id: "THIRD_GRADE_SCHOOL_DISTRICT_SBSA_MATH_PCTPROFICIENT_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "EIGHTH_GRADE_SCHOOL_DISTRICT_SBSA_MATH_PCTPROFICIENT_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      },
      {
        id: "ELEVENTH_GRADE_SCHOOL_DISTRICT_SAT_MATH_PCTPROFICIENT_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      }
    ]
  },
  {
    id: "enrollmentAndGraduation",
    name: "Student Enrollment and Graduation Rates",
    caption: `${ECDC_NAME} / Education`,
    about: `
      Like educational achievement, educational attainment has historically been linked
      with race, with white and Asian Amnericans in general leveraging greater access to
      educational resources (including higher quality schools and extra-curricular activities)
      into higher rates of progression and graduation.
    `,
    subSections: [
      /* FIXME: missing data for districts.
      {
        id: "SCHOOL_ENROLLMENT_CENSUS_TOTAL_PERCENT",
        stratumGroups: [MhcStratumGroupEnum.Sex]
      },
      */
      {
        id: "SCHOOL_DISTRICT_4_YEAR_GRADUATION_RATE_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      }
      /* FIXME: missing data for districts.
      {
        id: "EDUCATIONAL_ATTAINMENT_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      }
      */
    ]
  }
];
