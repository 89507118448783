import { EcdcPageSection } from "./types";
import { MhcStratumGroupEnum } from "graphqlApi/types";

import { ECDC_NAME } from "common/constants";

export const ecdcHealthcareSections: Array<EcdcPageSection> = [
  {
    id: "preventativeHealthcare",
    name: "Preventative Healthcare",
    caption: `${ECDC_NAME} / Healthcare`,
    about: `
      Regular physicals, screenings, vaccinations, and other preventative health measures
      are critical to preventing disease and disability. Disparities in preventative
      healthcare can be indicative of inequities in healthcare access, differing environmental
      exposures (e.g. local air quality), service gaps, and other systemic factors potentially
      linked to race, gender, and other identities.
    `,
    subSections: [
      {
        id: "CHOLSCREEN_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "MAMMOUSE_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "CERVICAL_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "COLON_SCREEN_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "HEALTH_INSURANCE_CENSUS_TOTAL_PERCENT",
        stratumGroups: [
          MhcStratumGroupEnum.Ethnicity,
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Age
        ]
      }
    ]
  },
  {
    id: "chronicDisease",
    name: "Chronic Disease and Health Outcomes",
    caption: `${ECDC_NAME} / Healthcare`,
    about: `
      The prevalence of persistent disease and disabling conditions that require ongoing
      medical attention or otherwise impact day-to-day activities is a clear barometer of
      population health. Chronic diseases are among the leading causes of death and
      disability in the U.S., and inequities across such measures can help reveal patterns
      of unequal access to care and health insurance as well as correlations with economic
      factors such as access to stable housing.
    `,
    subSections: [
      {
        id: "CANCER_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "COPD_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "CHD_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "DIABETES_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      },
      {
        id: "STROKE_CDC_PLACES_CRUDE_RATE",
        stratumGroups: []
      }
    ]
  },
  {
    id: "ACEs",
    name: "Adverse Childhood Experiences",
    caption: `${ECDC_NAME} / Healthcare`,
    about: `
      An adverse childhood experience is a potentially traumatic event—such as one involving
      violence, death, neglect, or mental health or substance use problems—that occurs between
      the ages of 0 and 17. Such experiences can be linked to health and wellbeing later in life,
      and disparities in the data may provide evidence of where support services have been
      historically lacking or are currently in need.
    `,
    subSections: [
      {
        id: "ALL_SCHOOL_SURVEY_V2_ACES_HOMELESS_CRUDE_RATE",
        stratumGroups: [MhcStratumGroupEnum.Sex, MhcStratumGroupEnum.Race]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_ACES_PARENT_INCARCERATED_CRUDE_RATE",
        stratumGroups: [
          MhcStratumGroupEnum.Sex,
          MhcStratumGroupEnum.Race,
          MhcStratumGroupEnum.Ethnicity
        ]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_ACES_ADULT_VIOLENCE_CRUDE_RATE",
        stratumGroups: [MhcStratumGroupEnum.Sex, MhcStratumGroupEnum.Race]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_ACES_HIT_BY_ADULT_CRUDE_RATE",
        stratumGroups: [MhcStratumGroupEnum.Sex, MhcStratumGroupEnum.Race]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_ACES_HIT_BY_TEEN_CRUDE_RATE",
        stratumGroups: [MhcStratumGroupEnum.Sex, MhcStratumGroupEnum.Race]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_ACES_BULLIED_CRUDE_RATE",
        stratumGroups: [MhcStratumGroupEnum.Sex, MhcStratumGroupEnum.Race]
      },
      {
        id: "ALL_SCHOOL_SURVEY_V2_NUMBER_ACES_AVG",
        stratumGroups: [MhcStratumGroupEnum.Sex, MhcStratumGroupEnum.Race]
      }
    ]
  },
  {
    id: "covid",
    name: "COVID Statistics",
    caption: `${ECDC_NAME} / Healthcare`,
    about: `
      Issues of equity—access to testing and vaccinations; differing vulnerability of workers,
      older adults, and others—have been central to the COVID-19 pandemic response. In addition to the
      case and vaccination data presented in the Data Center, My Healthy Community maintains
      a comprehensive COVID-19 dashboard featuring current (updated daily) and historical data
      about the pandemic.
    `,
    subSections: []
  }
];
