import { TopicDashboardSectionConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { subSections as riskFactorsSections } from "modules/Topics/util/elementHelpers/Lead/risk_factors/subSections";
import { subSections as housingSections } from "modules/Topics/util/elementHelpers/Lead/testing_coverage_and_housing/subSections";
import { subSections as vulnerableSections } from "modules/Topics/util/elementHelpers/Lead/testing_coverage_and_vulnerable_communities/subSections";
import { subSections as testResultSections } from "modules/Topics/util/elementHelpers/Lead/testing_rates_and_results/subSections";

const leadSections: TopicDashboardSectionConfig[] = [
  {
    id: "leadOverview",
    title: "Overview",
    subSections: []
  },
  {
    id: "test-results",
    title: "Children Lead Testing Rates and Results",
    subSections: testResultSections
  },
  {
    id: "risk-factors",
    title: "Lead Poisoning Risk Factors",
    subSections: riskFactorsSections
  },
  {
    id: "housing",
    title: "Assessing Lead Testing Coverage and Age of Housing Stock",
    subSections: housingSections
  },
  {
    id: "testing-coverage",
    title: "Assessing Lead Testing Coverage in Vulnerable Communities",
    subSections: vulnerableSections
  },
  {
    id: "additional-information",
    title: "Additional Information",
    subSections: []
  }
];

export default leadSections;
