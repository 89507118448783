"use client";

import isNil from "lodash/isNil";

import useRouterPath from "common/util/hooks/usePathWithParamKeys";

import {
  DashboardBasePaths,
  DashboardHrefFuncs,
  DashboardPage,
  DashboardPagesMap,
  ValidDashboardNames
} from "./dashboardConfigurations";

export type DashboardPageMap = Record<string, DashboardPage>;

export interface GetPageHrefProps {
  pageId: string;
  locationId?: string;
  districtId?: string;
  anchorId?: string;
  dashboard: ValidDashboardNames;
  seasonId?: string;
}

export const getPageHref = ({
  pageId,
  locationId,
  anchorId,
  dashboard,
  seasonId
}: GetPageHrefProps) => {
  const func = DashboardHrefFuncs[dashboard];
  if (!isNil(func)) return func({ pageId, locationId, anchorId, dashboard });
  const pageMap = DashboardPagesMap[dashboard];
  const basePath = DashboardBasePaths[dashboard];
  const pageData = pageMap[pageId] as DashboardPage;

  // It can be used as a utility function in this case.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { params } = useRouterPath();

  let newPath = basePath
    .replace(
      "[topicSlug]",
      pageData.id === "overview" && pageData.topicSlug !== "overview"
        ? `${pageData.topicSlug + "/"}overview`
        : pageData.topicSlug ?? ""
    )
    .replace("[locationId]", locationId ?? "state");
  Object.entries(params).forEach(([key, value]) => {
    if (typeof key !== "string" || typeof value !== "string") return;
    if (key !== "locationId" && !key.toLowerCase().includes("topic")) {
      newPath = newPath.replace(`[${key}]`, value);
    }
  });
  return newPath.replace("[seasonId]", seasonId ?? "2024-2025") + (anchorId ? "#" + anchorId : "");
};
