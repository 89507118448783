import { NextParsedUrlQuery } from "next/dist/server/request-meta";

import { FuzzySearchLocation } from "./types";

interface PathRedirect {
  base: string;
  redirect: string;
}

const EXCEPTION_REDIRECTS: PathRedirect[] = [
  {
    base: "/portals/ecdc",
    redirect: "/portals/ecdc/locations/[locationId]"
  }
];

export const fuzzySearchRouterContainsException = (pathname: string): string | undefined => {
  const filtered = EXCEPTION_REDIRECTS.filter((exception) => exception.base === pathname);
  if (filtered.length > 0) {
    return filtered[0]?.redirect ?? undefined;
  }
  return undefined;
};

interface CreateUpdateLocationPathProps {
  slug: string;
  pathname: string;
  exceptionLocation?: string;
  routerQuery: NextParsedUrlQuery;
}

// TODO: delete
export const createUpdateLocationPath = ({
  slug,
  pathname,
  exceptionLocation,
  routerQuery
}: CreateUpdateLocationPathProps): string => {
  const baseString = exceptionLocation !== undefined ? exceptionLocation : pathname;
  let updatedPathName = slug
    ? baseString.replace("[locationId]", slug).replace("state", slug)
    : baseString;
  Object.keys(routerQuery).forEach((valueKey) => {
    const value = routerQuery[valueKey];
    if (typeof value === "string" && valueKey !== "locationId") {
      updatedPathName = updatedPathName.replace(`[${valueKey}]`, value);
    }
  });
  return updatedPathName;
};

interface UpdateFuzzySearchLocationProps {
  location: FuzzySearchLocation;
  pathname: string;
  exceptionLocation?: string;
  params: NextParsedUrlQuery;
}

const updateFuzzySearchLocation = ({
  location,
  pathname,
  exceptionLocation,
  params
}: UpdateFuzzySearchLocationProps): FuzzySearchLocation => {
  const slug = location.slug;
  if (
    (slug !== null &&
      slug !== undefined &&
      (pathname.includes("[locationId]") ||
        pathname.includes("[districtId]") ||
        pathname.includes("state"))) ||
    exceptionLocation
  ) {
    const updatedPathName = createUpdateLocationPath({
      slug: slug as string,
      pathname,
      exceptionLocation,
      routerQuery: params
    });
    return { ...location, path: updatedPathName };
  } else {
    return location;
  }
};

export type LocationPathRecreation = {
  locations: FuzzySearchLocation[];
  pathname: string;
  params: NextParsedUrlQuery;
};

export const locationPathRecreation = ({
  locations = [],
  pathname,
  params
}: LocationPathRecreation): FuzzySearchLocation[] => {
  const exceptionLocation = fuzzySearchRouterContainsException(pathname);
  return locations.map((location) =>
    updateFuzzySearchLocation({
      location,
      pathname,
      exceptionLocation,
      params
    })
  );
};
