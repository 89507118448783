import { EcdcPageSection } from "./types";

import { ECDC_NAME } from "common/constants";

export const ecdcOverviewSections: Array<EcdcPageSection> = [
  {
    id: "population",
    name: "Population",
    caption: `${ECDC_NAME} / Overview`,
    subSections: [
      {
        id: "POPULATION_DENSITY",
        stratumGroups: []
      },
      {
        id: "POPULATION_TOTAL_COUNT",
        stratumGroups: []
      }
    ]
  }
];
