import { BaseProps, TopicElementBaseRender } from "modules/Topics/types";

import { TopicOverview } from "modules/Topics/components/TopicOverview";

export const getOverviewElement = <P extends BaseProps>(id: string): TopicElementBaseRender<P> => {
  return {
    id,
    render: ({ topic, locationNotAvailable }) =>
      topic &&
      !locationNotAvailable && (
        <TopicOverview topicSlug={topic?.slug} topicDescription={topic?.description} />
      )
  };
};
// TODO: Delete
