import {
  basePath as dodBasePath,
  dashboardSlug as dodSlug,
  NAME as DOD_NAME,
  pagesMap as dodPagesMap
} from "app/topics/drug-overdose-deaths/[subTopicId]/[locationId]/config";

import { ASTHMA_NAME, COVID_NAME, ECDC_NAME, FLU_NAME } from "common/constants";
import { ecdcPage, ecdcSubPagesMap } from "modules/ECDC/content/ecdcPages";
import { ecdcPagePath } from "modules/ECDC/util/ecdcPageRoutes";
import { FluSections } from "modules/Flu/util/sections";

import { NavItem } from "common/components/NavTabs";
import { GetPageHrefProps } from "./pageHref";

export const validDashboardNames = ["asthma", "covid-19", "ecdc", "flu", dodSlug] as const;
export type ValidDashboardNames = (typeof validDashboardNames)[number];
export type DashboardDictionary<T> = Record<ValidDashboardNames, T>;

export interface DashboardPage extends NavItem {
  locationId: string;
  topicSlug?: string;
  anchorId?: string;
}

export type DashboardPageConfig = Omit<DashboardPage, "href" | "locationId">;
export type DashboardPageConfigs = Record<string, Omit<DashboardPage, "href" | "locationId">>;

export const DashboardPagesMap: DashboardDictionary<DashboardPageConfigs> = {
  "covid-19": {
    overview: {
      id: "overview",
      name: "Overview",
      topicSlug: "covid-19"
    },
    cases: {
      id: "cases",
      name: "Cases",
      topicSlug: "covid-19/cases"
    },
    vaccines: {
      id: "vaccines",
      name: "Vaccines",
      topicSlug: "covid-19/vaccines"
    },
    hospitalizations: {
      id: "hospitalizations",
      name: "Hospitalizations",
      topicSlug: "covid-19/hospitalizations"
    },
    deaths: {
      id: "deaths",
      name: "Deaths",
      topicSlug: "covid-19/deaths"
    }
  },
  flu: {
    ...FluSections
  },
  ecdc: {
    overview: ecdcPage,
    ...ecdcSubPagesMap
  },
  asthma: {
    overview: {
      id: "overview",
      name: "Overview",
      topicSlug: "asthma"
    },
    "er-visits": {
      id: "er-visits",
      name: "Emergency Department Visits",
      topicSlug: "asthma/er-visits"
    },
    hospitalizations: {
      id: "hospitalizations",
      name: "Hospitalizations",
      topicSlug: "asthma/hospitalizations"
    },
    deaths: {
      id: "deaths",
      name: "Deaths",
      topicSlug: "asthma/deaths"
    }
  },
  [dodSlug]: dodPagesMap
};

export const DashboardBasePaths: DashboardDictionary<string> = {
  "covid-19": "/topics/[topicSlug]/[locationId]/[seasonId]",
  flu: "/topics/flu/[topicSlug]/[locationId]/[seasonId]",
  ecdc: "/portals/ecdc/locations/[locationId]/[topicSlug]",
  asthma: "/topics/[topicSlug]/[locationId]",
  [dodSlug]: dodBasePath
};

export const DashboardTitle: DashboardDictionary<string> = {
  "covid-19": COVID_NAME,
  ecdc: ECDC_NAME,
  flu: FLU_NAME,
  asthma: ASTHMA_NAME,
  [dodSlug]: DOD_NAME
};

export const DashboardSubtitle: DashboardDictionary<string> = {
  "covid-19": "Sections in the dashboard",
  ecdc: "Sections in the data center",
  flu: "Sections in this portal",
  asthma: "Sections in the dashboard",
  [dodSlug]: "Sections in the dashboard"
};

export const DashboardFooterTitle: DashboardDictionary<string> = {
  "covid-19": "Sections in the COVID-19 dashboard",
  ecdc: "Sections in this portal",
  flu: "Sections in Influenza Dashboard",
  asthma: "Sections in the dashboard",
  [dodSlug]: "Sections in the dashboard"
};

export const DashboardHrefFuncs: Record<string, (props: GetPageHrefProps) => string> = {
  ecdc: ecdcPagePath
};
