"use client";

import { useCallback, useEffect, useMemo, useRef } from "react";
import { dateTimeLabelFormats, granularityTimeScaleLookup, tickInterval } from "./highchartsConfig";
import { Chart, setOptions, YAxisOptions } from "highcharts";
import Highcharts from "highcharts/highstock";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsMore from "highcharts/highcharts-more";
import { HighchartsReact } from "highcharts-react-official";

import { RawChartSeries } from "./Chart/types";
import { TimeScale } from "./types";
import { MhcStatUnitEnum } from "common/util/types";
import { MhcConfidenceInterval, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { xDateFormat } from "./util/date";
import { ExportingConfigration } from "./util/exportingConfiguration";
import {
  baseChartOptions,
  baseXAxisOptions,
  baseYAxisOptions,
  highchartOptions,
  plotOptions
} from "./util/options";
import { chartTooltipFormatter, defaultTooltipOptions, pointsFromContext } from "./util/tooltip";
import useChartDateControls from "./util/useChartDateControls";
import useSeriesInfo from "./util/useSeriesInfo";
import { seriesForLineChart } from "common/components/charts/util/series";
import { formatDateFromUTC } from "common/util/utcDateFromString";

import { ChartStackAlert } from "../Alerts/ChartStackAlert";

if (typeof window !== "undefined") {
  highchartsAccessibility(Highcharts);
}

export type OverrideXDateFormatter = ({
  date,
  granularity
}: {
  date: number;
  granularity?: MhcTimeSeriesGranularityEnum;
}) => string;

export type LineChartSeries = RawChartSeries;
export interface LineChartXAxisEnhancement {
  xCategories?: string[];
  xCategoriesReadableDict?: Record<string, string>;
  categoryName?: string;
  minTickInterval?: number | null;
  enableXAxisLabel?: boolean;
  type?: Highcharts.AxisTypeValue;
  overrideXLabelFormatter?: Highcharts.AxisLabelsFormatterCallbackFunction;
  overrideXDateFormat?: string;
  overrideXDateFormatter?: OverrideXDateFormatter;
  plotBands?: Highcharts.AxisPlotBandsOptions[];
  plotLines?: Highcharts.AxisPlotLinesOptions[];
  tickmarkPlacement?: "on" | "between";
}

export interface LineChartProps {
  chartRefCallback?: (chart: Chart) => void;
  dateString?: string;
  granularity?: MhcTimeSeriesGranularityEnum;
  height?: number;
  hideTitleOnUI?: boolean;
  lineMarksEnabled?: boolean;
  markersOn?: boolean;
  overrideTooltipFormatter?: Highcharts.TooltipFormatterCallbackFunction;
  percent?: boolean;
  pointCountToEnableZoom?: number;
  precision?: number;
  series: RawChartSeries[];
  shareTooltip?: boolean;
  showConfidenceIntervals?: boolean;
  subtitle?: string;
  statCaption?: string;
  timeScale?: TimeScale;
  title: string;
  tooltipTitle?: string;
  unit?: MhcStatUnitEnum;
  useNavigator?: boolean;
  useRangeSelector?: boolean;
  useRangeSelectorButtons?: boolean;
  useScrollbar?: boolean;
  width?: number;
  xAxisEnhancements?: LineChartXAxisEnhancement;
  xAxisTitle?: string;
  yAxisOptions?: YAxisOptions;
  yAxisTitleText?: string;
}

export const LineChart: React.FC<LineChartProps> = ({
  chartRefCallback,
  dateString,
  granularity,
  height,
  hideTitleOnUI = true,
  lineMarksEnabled = false,
  markersOn = false,
  overrideTooltipFormatter,
  percent,
  pointCountToEnableZoom = 52,
  precision = 2,
  series,
  shareTooltip = true,
  showConfidenceIntervals = false,
  subtitle,
  timeScale,
  title = "",
  tooltipTitle,
  unit,
  useNavigator = false,
  useRangeSelector = false,
  useRangeSelectorButtons = false,
  useScrollbar = false,
  width,
  xAxisEnhancements = { enableXAxisLabel: true, type: "datetime" },
  xAxisTitle,
  yAxisOptions = {},
  statCaption,
  yAxisTitleText
}) => {
  useMemo(() => {
    highchartsMore(Highcharts);
  }, []);

  const chartRef = useRef<Chart>();

  const confidenceIntervals = useMemo(
    () =>
      series
        .filter((s) => s.confidenceIntervals?.length)
        .map((s) => s.confidenceIntervals as MhcConfidenceInterval[]),
    [series]
  );

  const _timeScale: TimeScale | undefined = useMemo(() => {
    if (timeScale === undefined && granularity !== undefined) {
      return granularityTimeScaleLookup[granularity];
    } else if (timeScale === undefined) {
      return "yearly";
    }
    return timeScale;
  }, [timeScale, granularity]);

  const [seriesPointCount, seriesMax] = useSeriesInfo([], series);

  const { rangeSelector, navigator, scrollbar } = useChartDateControls({
    granularity,
    useScrollbar,
    useNavigator,
    useRangeSelector,
    useRangeSelectorButtons,
    pointCountToEnableZoom,
    overrideRangeSelectorButtons: undefined,
    seriesPointCount
  });

  const setChart = useCallback(
    (chart: Chart) => {
      chartRef.current = chart;
      if (chartRefCallback !== undefined) {
        chartRefCallback(chart);
      }
    },
    [chartRefCallback]
  );

  useEffect(() => chartRef?.current?.reflow(), []);

  if (series.every((s) => s.values.length <= 1)) {
    return <ChartStackAlert />;
  }

  const options: Highcharts.Options = {
    ...baseChartOptions({
      height,
      width,
      hideTitleOnUI,
      title,
      subtitle,
      dateString
    }),
    tooltip: {
      ...defaultTooltipOptions,
      xDateFormat:
        xAxisEnhancements?.overrideXDateFormat !== undefined
          ? xAxisEnhancements?.overrideXDateFormat
          : xDateFormat(granularity),
      useHTML: true,
      formatter:
        overrideTooltipFormatter !== undefined
          ? overrideTooltipFormatter
          : function () {
              return chartTooltipFormatter({
                points: pointsFromContext(this),
                percent,
                unit,
                precision,
                title: tooltipTitle ?? title,
                subtitle,
                xAxisEnhancements,
                granularity,
                statCaption,
                confidenceIntervals: showConfidenceIntervals ? confidenceIntervals ?? [] : []
              });
            },
      shared: shareTooltip
    },
    rangeSelector,
    navigator,
    scrollbar,
    exporting: ExportingConfigration(title, subtitle, dateString),
    yAxis: {
      ...baseYAxisOptions({ percent, precision, unit, yAxisTitle: yAxisTitleText, seriesMax }),
      ...yAxisOptions
    },
    xAxis: {
      ...baseXAxisOptions({ xAxisTitle }),
      categories: xAxisEnhancements?.xCategories,
      type: xAxisEnhancements?.type,
      dateTimeLabelFormats,
      tickmarkPlacement: xAxisEnhancements?.tickmarkPlacement ?? "on",
      minTickInterval:
        xAxisEnhancements?.minTickInterval !== undefined
          ? xAxisEnhancements?.minTickInterval === null
            ? undefined
            : xAxisEnhancements?.minTickInterval
          : tickInterval(_timeScale),
      labels: {
        enabled: xAxisEnhancements?.enableXAxisLabel ?? true,
        style: {
          color: "black"
        },
        formatter: xAxisEnhancements?.overrideXLabelFormatter
          ? xAxisEnhancements?.overrideXLabelFormatter
          : ({ value }) => {
              return formatDateFromUTC(value, granularity, undefined, "short") ?? `${value}`;
            }
      },
      plotLines: xAxisEnhancements?.plotLines,
      plotBands: xAxisEnhancements?.plotBands
    },
    plotOptions: {
      line: plotOptions({ lineMarksEnabled }).line
    },
    series: seriesForLineChart({
      series,
      seriesType: xAxisEnhancements?.type ?? "datetime",
      markersOn,
      showConfidenceIntervals
    })
  };
  setOptions(highchartOptions);
  return <HighchartsReact highcharts={Highcharts} options={options} callback={setChart} />;
};

export default LineChart;
