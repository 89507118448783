// TODO: Refactor element functions to *not* include useRouter or other hooks
"use client";

import { defaultTopicDashboardConfig } from "modules/Topics/pages/DefaultTopicDashboardPageConfig";
import { getOverviewElement } from "./overview";
import { getRiskFactorsElement } from "./risk_factors";
import { getHousingElement } from "./testing_coverage_and_housing";
import { getVulerableCommunitiesElement } from "./testing_coverage_and_vulnerable_communities";
import { getTestResultsElement } from "./testing_rates_and_results";

const leadSections = [
  getOverviewElement(),
  getTestResultsElement(),
  getRiskFactorsElement(),
  getHousingElement(),
  getVulerableCommunitiesElement(),
  defaultTopicDashboardConfig.elements[1]
];

export default leadSections;
