"use client";

import { Typography } from "@mui/material";
import introImage from "public/images/topics/lead/xavi-cabrera-wuHWHPQZtvY-unsplash.jpg";

import { TopicDashboardSectionProps } from "../../dashboard/types";
import { TopicElementBaseRender } from "modules/Topics/types";

import { getTopicDashboardSection } from "modules/Topics/util/getTopicDashboardSection";

import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { TopicDashboardSection } from "modules/Topics/components/TopicDashboardSection";
import { getLocationName } from "../..";
import { sectionContentDictionary } from "./sectionContentDictionary";

export const getRiskFactorsElement = <P extends { sections?: TopicDashboardSectionProps[] }>(
  id?: string
): TopicElementBaseRender<P> => ({
  id: id ?? "risk-factors",
  render: ({ topic, sections, location }) => {
    if (!topic) return null;

    const intro = (
      <>
        <ContentCardTextBlock sx={{ mb: 0 }}>
          <Typography>
            Across the United States, there are a variety of childhood lead exposure sources and
            risk factors.
          </Typography>

          <Typography>
            Those who live in housing built before 1978 are at the greatest risk of lead exposure.
            Houses built before 1978, when the use of lead in paint was banned, and houses in
            low-income areas, many of which have homes built before 1978, are more likely to contain
            lead-based paint and have pipes, faucets, and plumbing fixtures containing lead. Also,
            African Americans are at a higher risk of lead exposure due to systemic racism leading
            to poor housing stock.
          </Typography>

          <Typography>
            Children less than six years old are at a higher risk of lead exposure. This is because
            their bodies are rapidly developing and more susceptible to taking in lead if exposed.
            Young children also tend to put their hands or other objects into their mouths. This is
            why the most common source of lead exposure in young children is lead dust that they
            swallow after placing their lead-contaminated hands or other objects in their mouths.
          </Typography>
        </ContentCardTextBlock>
      </>
    );

    return (
      <TopicDashboardSection
        {...getTopicDashboardSection(sections ?? [], "risk-factors")}
        intro={intro}
        locationName={getLocationName(location)}
        sectionContentDictionary={sectionContentDictionary}
        image={{
          src: introImage,
          alt: "Young happy girl with purple hair pretending to be a fairy or wizard",
          credit: {
            name: "Xavi Cabrera",
            url: "//unsplash.com/photos/wuHWHPQZtvY"
          }
        }}
        currentTopicSlug={topic.slug}
      />
    );
  }
});
