"use client";

import { useCallback, useEffect, useMemo, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { dateTimeLabelFormats, tickInterval } from "../highchartsConfig";
import { setOptions, XAxisOptions } from "highcharts";
import Highcharts from "highcharts/highstock";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsMore from "highcharts/highcharts-more";
import { HighchartsReact } from "highcharts-react-official";

import { ChartProps, ChartXAxisEnhancement } from "./types";

import { xDateFormat } from "../util/date";
import { ExportingConfigration } from "../util/exportingConfiguration";
import {
  baseChartOptions,
  baseXAxisOptions,
  baseYAxisOptions,
  highchartOptions,
  plotOptions
} from "../util/options";
import { isRenderableChartSeries } from "../util/series";
import { chartTooltipFormatter, defaultTooltipOptions, pointsFromContext } from "../util/tooltip";
import useChartDateControls from "../util/useChartDateControls";
import useSeriesInfo from "../util/useSeriesInfo";
import {
  axisNudgingElement,
  updateOptionsForQuarterlyData,
  useTimeScale
} from "common/components/charts/Chart/util";
import { capitalizeFirstLetter } from "common/util/helpers/string";
import { formatDateFromUTC } from "common/util/utcDateFromString";

import { InvestigateChartTitlesFallback } from "common/components/charts/Investigate/InvestigateChartTitles";
import { StateLoadingIndicator } from "common/components/GeoMap/StateLoadingIndicator";
import { ChartStackAlert } from "../../Alerts/ChartStackAlert";

export const ChartFallback = ({ height = 500, includeTitles = false }) => (
  <Stack gap={4}>
    {includeTitles && <InvestigateChartTitlesFallback />}
    <StateLoadingIndicator loading={true} width={"100%"} height={height}>
      <Typography variant="body2" pl={2}>
        Data loading for chart...
      </Typography>
    </StateLoadingIndicator>
  </Stack>
);

if (typeof window !== "undefined") {
  highchartsAccessibility(Highcharts);
}

const Chart: React.FC<ChartProps> = ({
  animate = true,
  chartOptions,
  chartRefCallback,
  confidenceIntervals,
  dateString,
  granularity,
  height,
  hideTitleOnUI = true,
  immutable = false,
  isPercent: _isPercent,
  lineMarksEnabled = false,
  overridePlotOptions = {},
  overrideTooltipFormatter,
  pointCountToEnableZoom = 52,
  precision: overridePrecision,
  overrideRangeSelectorButtons,
  series,
  shareTooltip = true,
  showConfidenceIntervals = false,
  statCaption: overrideStatCaption,
  statIdentifier,
  subtitle: overrideSubtitle,
  timeScale,
  title = "",
  tooltipContent,
  unit: overrideUnit,
  useNavigator = false,
  useRangeSelector = false,
  useRangeSelectorButtons = false,
  useScrollbar = false,
  width,
  xAxisEnhancements = { enableXAxisLabel: true, type: "datetime" },
  xAxisOptions = {},
  xAxisTitle,
  yAxisOptions = {},
  yAxisTitle: overrideYAxisTitle
}) => {
  // Enable highcharts-more module for supporting `arearange` chart type, etc
  useMemo(() => highchartsMore(Highcharts), []);
  const chartRef = useRef<Highcharts.Chart>();

  const statCaption = overrideStatCaption ?? statIdentifier?.statCaption ?? "";
  const subtitle = overrideSubtitle ?? statIdentifier?.subtitle ?? "";
  const unit = overrideUnit ?? statIdentifier?.unit ?? "";
  const yAxisTitle = capitalizeFirstLetter(
    !isNil(overrideYAxisTitle) && !isEmpty(overrideYAxisTitle)
      ? overrideYAxisTitle
      : statIdentifier?.subtitle ?? ""
  );
  const precision = overridePrecision ?? statIdentifier?.precision ?? 1;
  const isPercent = _isPercent ?? statIdentifier?.isPercent ?? false;

  const _timeScale = useTimeScale({ timeScale, granularity });

  const _series = useMemo(() => {
    const mappedSeries = (() => {
      if (showConfidenceIntervals) return series;
      return series.filter((s) => !s.id?.includes("confidence"));
    })();
    // We agreed to not show series that don't have data in the chart.
    // but in case someone is debugging in the future why a series is not being displayed
    // it could be that there's no data, this is done at the chart component level
    // to ensure that this behavior is applied to all charts.
    //
    // Your data loading might be working as expected, but you could still not see the series
    // if there's no data in it.
    return mappedSeries
      .filter((series) => series.data?.length !== 0)
      .map((series) => ({ ...series, connectNulls: true }));
  }, [series, showConfidenceIntervals]);

  if (granularity === "quarter" && series.length > 4) {
    updateOptionsForQuarterlyData({ xAxisEnhancements, xAxisOptions, overridePlotOptions });
  }

  const [seriesPointCount, seriesMax] = useSeriesInfo(series);

  const { rangeSelector, navigator, scrollbar } = useChartDateControls({
    granularity,
    useScrollbar,
    useNavigator,
    useRangeSelector,
    useRangeSelectorButtons,
    pointCountToEnableZoom,
    overrideRangeSelectorButtons,
    seriesPointCount
  });
  const xAxis = useMemo(() => {
    const baseOptions = baseXAxisOptions({ xAxisTitle });
    let _xAxisOptions: XAxisOptions = {
      ...baseOptions,
      categories: xAxisEnhancements?.xCategories,
      type:
        xAxisEnhancements?.type ?? xAxisEnhancements?.xCategories?.length ? "category" : "datetime",
      plotLines: xAxisEnhancements?.plotLines,
      plotBands: xAxisEnhancements?.plotBands,
      labels: xAxisEnhancements?.labels ?? {},
      ...xAxisOptions
    };

    if (!xAxisEnhancements?.xCategories) {
      _xAxisOptions = {
        ...baseOptions,
        type: "datetime",
        categories: undefined,
        dateTimeLabelFormats,
        tickmarkPlacement: xAxisEnhancements?.tickmarkPlacement ?? "on",
        minTickInterval:
          xAxisEnhancements?.minTickInterval !== undefined
            ? xAxisEnhancements?.minTickInterval === null
              ? undefined
              : xAxisEnhancements?.minTickInterval
            : tickInterval(_timeScale),
        labels: {
          allowOverlap: false,
          enabled: xAxisEnhancements?.enableXAxisLabel ?? true,
          style: { color: "black" },
          useHTML: granularity === "quarter",
          formatter: xAxisEnhancements?.overrideXLabelFormatter
            ? xAxisEnhancements?.overrideXLabelFormatter
            : ({ value, axis }) => {
                let formattedValue =
                  formatDateFromUTC(value, granularity, undefined, "short") ?? `${value}`;
                if (granularity === "quarter" && axis?.tickPositions?.[0] === value) {
                  formattedValue = axisNudgingElement(formattedValue);
                }
                return formattedValue;
              },
          ..._xAxisOptions.labels
        },
        startOnTick: true,
        plotLines: xAxisEnhancements?.plotLines
      };
    }
    return _xAxisOptions;
  }, [_timeScale, granularity, xAxisEnhancements, xAxisOptions, xAxisTitle]);

  const setChart = useCallback(
    (chart: Highcharts.Chart) => {
      chartRef.current = chart;
      if (chartRefCallback !== undefined) {
        chartRefCallback(chart);
      }
    },
    [chartRefCallback]
  );

  useEffect(() => chartRef?.current?.reflow(), []);

  if (!isRenderableChartSeries(series)) {
    return <ChartStackAlert height={height} />;
  }

  const options: Highcharts.Options = {
    ...baseChartOptions({
      height,
      width,
      hideTitleOnUI,
      title,
      subtitle,
      dateString,
      animate,
      chartOptions
    }),
    tooltip: {
      ...defaultTooltipOptions,
      xDateFormat:
        xAxisEnhancements?.overrideXDateFormat !== undefined
          ? xAxisEnhancements?.overrideXDateFormat
          : xDateFormat(granularity),
      useHTML: true,
      formatter:
        overrideTooltipFormatter !== undefined
          ? overrideTooltipFormatter
          : function () {
              return chartTooltipFormatter<ChartXAxisEnhancement>({
                points: pointsFromContext(this),
                percent: isPercent,
                unit,
                precision,
                title: tooltipContent?.title ?? title,
                statCaption,
                subtitle,
                xAxisEnhancements,
                granularity,
                confidenceIntervals: showConfidenceIntervals ? confidenceIntervals ?? [] : [],
                customDateString: tooltipContent?.date
              });
            },
      shared: shareTooltip
    },
    rangeSelector,
    navigator,
    scrollbar,
    exporting: ExportingConfigration(title, subtitle, dateString),
    yAxis: {
      ...baseYAxisOptions({
        seriesMax,
        percent: isPercent,
        precision,
        unit,
        yAxisTitle,
        options: yAxisOptions
      })
    },
    xAxis,
    plotOptions: plotOptions({ lineMarksEnabled, options: overridePlotOptions }),
    series: _series
  };
  setOptions(highchartOptions);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      callback={setChart}
      immutable={immutable}
    />
  );
};

export default Chart;
