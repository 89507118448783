import { ComponentType } from "react";

import Diabetes from "./items/Diabetes";
import MentalHealthSubstanceUseOverview from "./items/MentalHealthSubstanceUseOverview";

export const topicOverviewDictionary: Record<string, ComponentType> = {
  "mental-health-substance-use": MentalHealthSubstanceUseOverview,
  diabetes: Diabetes
};
//TODO: move to app/ folder
