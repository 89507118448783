import { DashboardPageConfig } from "layout/DashboardLayout/util/dashboardConfigurations";

export const NAME = "Drug Overdose Deaths";
export const dashboardSlug = "drug-overdose-deaths";
export const basePath = "/topics/[topicSlug]/[locationId]";

const validSubPages = [
  "overview",
  "deaths-investigation-alerts",
  "sudors",
  "death-certificates"
] as const;
export type ValidSubPage = (typeof validSubPages)[number];

export const pagesMap: Record<ValidSubPage, DashboardPageConfig> = {
  overview: {
    id: "overview",
    name: "Overview",
    topicSlug: dashboardSlug
  },
  "deaths-investigation-alerts": {
    id: "deaths-investigation-alerts",
    name: "Deaths Investigation Alerts",
    topicSlug: `${dashboardSlug}/deaths-investigation-alerts`
  },
  sudors: {
    id: "sudors",
    name: "SUDORS",
    topicSlug: `${dashboardSlug}/sudors`
  },
  "death-certificates": {
    id: "death-certificates",
    name: "Death Certificates",
    topicSlug: `${dashboardSlug}/death-certificates`
  }
};

export const BASE_ATTRIBUTION_KEY = "dod";
